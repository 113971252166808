@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  font-family: "Roboto", "Poppins", sans-serif;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  padding-bottom: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  height: max-content;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  transform: scaleX(0);
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #2196f3;
  transform-origin: bottom right;
  transition: transform 0.2s;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  font-weight: 500;
}

.active:after {
  transform: scaleX(1);
}

.navbar {
  min-height: 80px;
  max-width: 100vw;
}

.nav-items {
  line-height: 40px;
  height: 40px;
}

.under-nav .nav-link {
  color: white !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.dropdown-item {
  font-size: 14px;
  line-height: 16px;
}

.under-nav {
  top: 80px;
}

.modal-subtitle {
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (max-width: 992px) {
  .nav-items {
    margin-top: 2rem;
  }
}

.nav-link {
  margin-left: 0.5rem;
}

.hero {
  min-height: 100vh;
  background-size: cover;
  padding-top: 18rem;
  color: white;
}

h1 {
  font-size: 62px;
  font-weight: bold;
  z-index: 3;
}

h2 {
  font-size: 48px;
  font-weight: normal;
}

h3 {
  font-size: 28px;
  font-weight: bold;
}

p {
  font-size: 18px;
}

.usell-u {
  border: 4px solid #2196f3;
  padding: 0 1rem;
  margin-right: 0.5rem;
}

.usell-u.smaller {
  border: 2px solid #2196f3;
  padding: 0 0.5rem;
  margin-right: 0.25rem;
}

.join-button {
  margin-top: 2rem;
  padding: 0.5rem 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: 4px solid #2196f3;
  color: white;
  font-size: 32px;
  font-weight: bold;
  transition-duration: 0.2s;
  border-radius: 4px;
}

.join-button:hover {
  background-color: #2196f3;
  color: white;
}

.join-button-cta {
  color: #2196f3;
  margin: 0 auto;
  display: block;
  font-size: 24px;
}

.content {
  padding: 0 0 0 0;
}

.content-padding-bottom {
  padding-bottom: 4rem;
}

.content-padding-bottom-lg {
  padding-bottom: 12rem;
}

.content-padding-top-lg {
  padding: 6rem;
}

.row {
  margin-bottom: 2rem;
}

.mission-statement {
  display: flex;
  padding-left: 8rem;
}

.mission-statement img {
  height: 48px;
  margin-right: 0.5rem;
}

.mission-statement h4 {
  font-size: 24px;
  color: #2196f3;
}

.video-player {
  max-width: 100%;
  border: 2px solid #2196f3;
  border-radius: 4px;
}

@media only screen and (max-width: 992px) {
  .video-player {
    margin: 0;
  }
}

.vertical-center {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.register {
  position: relative;
  display: flex;
  padding: 30px 0;
  margin: 0 auto;
  justify-content: space-between;
  color: white;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

.register-buyer {
  background-image: url("./../public/images/sell-overlay.jpg");
}

.register-seller {
  background-image: url("./../public/images/buy-overlay.jpg");
}

.register svg {
  position: absolute;
  display: block;
  width: 100px;
  top: 0;
  left: 0;
}

.register-content {
  margin: 2rem auto;
}

.register h5 {
  font-weight: bold;
}

.register h5,
.register p {
  text-decoration: none;
}

.bg-main {
  background-color: #2196f3;
  color: white;
}

.mission-logo {
  height: 36px;
  display: inline-block;
}

.our-mission h5 {
  display: inline-block;
  width: max-content;
  margin-left: 1rem;
  height: 36px;
  font-weight: bold;
  line-height: 26px;
}

.sale-duration {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #2196f3;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.car-details {
  margin: 0 auto;
  text-align: center;
}

.car-details p {
  font-size: 15px;
}

.after-icon {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
}

.svg-animation {
  min-height: 558px;
}

.swiper-slide {
  height: 200px;
}

.contact-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.85)),
    url("../public/images/USELL/Contact.jpg") center no-repeat fixed;
  background-size: cover;
  z-index: -1;
  transform: scale(1.3);
}

.contact-section {
  padding-bottom: 20px;
  background: url("../public/images/USELL/Lighttrail.jpeg") no-repeat;
  background-size: cover;
}

.contact-container {
  transform: translateY(-150px);
}

@media only screen and (min-width: 992px) {
  .contact-container .col {
    padding: 0 4rem 0 0;
  }
}

.form-wrapper {
  background-color: white;
  border-radius: 4px;
  padding: 1.5rem 3rem;
  -webkit-box-shadow: 7px 9px 5px -4px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 7px 9px 5px -4px rgba(0, 0, 0, 0.31);
  box-shadow: 7px 9px 5px -4px rgba(0, 0, 0, 0.31);
}

.form-wrapper .form-label {
  font-size: 12px;
}

.form-check-label {
  font-size: 12px;
  font-weight: bold;
}

.cf-textarea {
  max-height: 300px;
}

.submit-form {
  color: white;
  font-size: 16px;
  background-color: #2196f3;
}

.auctions-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.detail-auctions-background {
  background-position: center;
}

.appointments-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.appointments-title {
  color: White;
}

.agenda-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bids-background {
  padding-top: 25px;
  padding-bottom: 25px;
}

.agenda-title {
  color: white;
}

.profile-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.profile-title {
  color: white;
}

.winning-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.winning-title {
  color: white;
}

.following-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.following-title {
  color: white;
}

.user-auctions-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.user-auctions-background {
  background-image: url("../public/images/sell-overlay.jpg");
  background-position: top;
}

.create-auction-hero {
  position: relative;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.car-carousel img {
  min-width: 100%;
}

.bid-button {
  padding: 0.4rem 0.8rem;
  outline: none;
  border: none;
  background-color: #2196f3;
  color: white;
  font-weight: bold;
}

.bid-button-reverse {
  background-color: white;
  color: #2196f3;
}

.auction-link {
  max-width: max-content;
}

.fixed-size-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hover-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.admin-hover-card .row + .row {
  margin-top: 1rem;
}

.about-us-banner-picture {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/USELL/Hoe\ werkt.jfif");
  background-size: cover;
  background-position: center;
  height: 500px;
}

.logoover {
  padding-bottom: 65px;
}

/* Cards used at the AboutUsPage.jsx ---------------------------------------------------------------------------------*/
.custom-cards-col {
  border: none !important;
}

/* Cards used t the AboutUsPage.jsx END ------------------------------------------------------------------------------*/

/* parallax is being used at the AboutUsPage */
.parallax {
  background-image: url("../public/images/taycan_banner.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-logo-white {
  height: 80% !important;
  width: 80% !important;
  margin-top: 2rem;
}

/* FOOTER STUFF ---------------------------------------------------------------------------------------------------- */
.footer {
  position: relative;
  color: white;
  overflow: hidden;
  /* Ensure content does not overflow */
  padding-top: 3rem;
}

.footer-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url("../public/images/USELL/footer.jpg");
  background-size: cover;
  background-position: center;
  z-index: -1;
  /* Place the background behind the content */
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer li {
  list-style-type: none;
  margin: 20px;
  opacity: 80%;
}

.footer a {
  color: white;
  text-decoration: none !important;
}

.footer a:hover {
  text-decoration: underline;
}

/* FOOTER STUFF ABOVE -----------------------------------------------------------------------------------------------*/

/*==========FAQ COMPONENT CSS STYLES================*/

.faqs .faq {
  /*margin: 15px;*/
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 18px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  /*width: 30px;*/
  /*height: 30px;*/
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

/*==========FAQ COMPONENT CSS STYLES================*/

/*==========SPINNER CSS STYLES================*/
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  width: 100px;
  height: 100px;
}

/*==========SPINNER CSS STYLES================*/

/* DROPDOWN */

.nav-drop .dropdown-menu {
  z-index: 2000;
  position: relative;
}

.sticky-top {
  z-index: 0;
  position: static;
}

.titel-afspraken {
  padding-bottom: 1rem;
  padding-left: 5rem;
}

/*=====================CREAT AUCTION PAGE CSS========================= */

.form-container {
  max-width: 900px;
  margin: 0 auto;
}

.form-label {
  font-weight: bold;
  color: #333;
}

.form-control {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-control:focus {
  border-color: #2196f3;
  box-shadow: 0 0 8px rgba(33, 150, 243, 0.4);
}

.join-button-cta {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.join-button-cta:hover {
  background-color: #1769aa;
  transform: translateY(-2px);
}

.text-white {
  color: #fff !important;
}

/*=====================APPOINTMENT PAGE CSS========================= */

.appointment-card {
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.appointment-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.appointment-card .btn {
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.appointment-card .btn-primary:hover {
  background-color: #1769aa;
  color: white;
}

.appointment-card .btn-danger {
  background-color: #dc3545;
}

.appointment-card .btn-danger:hover {
  background-color: #c82333;
}

.title-box {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pending-box {
  background-color: #ffc107;
  /* Geel voor nog in te plannen */
  color: #fff;
}

.accepted-box {
  background-color: #28a745;
  /* Groen voor geplande */
  color: #fff;
}

.completed-box {
  background-color: #2196f3;
}

.titel-afspraken {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

/* INDEXPAGE */

.indexText {
  padding-top: 2rem;
}

/* AUCTIONPAGE */

.auctionText {
  padding-top: 2rem;
}

/* PROFILEPAGE */

.profile-section {
  background-color: #f8f9fa;
  padding-bottom: 40px;
}

/* Card styling */
.profile-card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-card-left {
  background: linear-gradient(135deg, #007bff, #b3d7ff);
  color: white;
  text-align: center;
  padding: 30px;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.profile-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
}

.profile-card-body {
  padding: 30px;
}

/* Divider styling */
.divider {
  border-top: 1px solid #dee2e6;
  margin: 20px 0;
}

/* Row spacing */
.profile-section .row {
  margin-bottom: 20px;
}

.profile-section .row:last-child {
  margin-bottom: 0;
}

/* Button styling */
.profile-section .btn {
  width: 100%;
}

.profile-section .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.profile-section .btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}

.profile-section .btn:hover {
  opacity: 0.9;
}

/* Modal custom styling */
.modal-header {
  background-color: #007bff;
  color: white;
}

.modal-header .close {
  color: white;
}

.modal-footer .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.modal-footer .btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}

.text-danger {
  color: #dc3545 !important;
}

.afspraken {
  padding-top: 25px;
}

.agenda-container {
  padding-top: 25px;
}

.detail {
  padding-top: 25px;
}

.car-carousel img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.veilingenpagina {
  padding-top: 25px;
}

.winnende {
  padding-top: 25px;
}

.volgende {
  padding-top: 25px;
}

.over-ons-titel {
  color: #007aff;
}

.carcard-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.stockcard_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.shadow-text {
  text-shadow: 1px 1px 1.5px rgba(0, 0, 0, 0.3);
}

.rounded-carousel-img {
  border-radius: 15px;
}

.car-specs-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.section-divider {
  border-top: 2px solid white;
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
}

.navbar .flag-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  display: block;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .our-mission {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .uw-veilingen {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .indexPage-content {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  .hero {
    padding-top: 50px !important;
  }

  .index-hero-text {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .index-paid-text {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .winning-background {
    height: 500px;
  }

  .winning-titel {
    width: 100%;
  }

  .following-background {
    height: 500px;
  }

  .auctions-background {
    height: 500px;
  }

  .won-background {
    height: 500px;
  }

  .carcard-img {
    height: 150px;
  }

  .stockcard_img {
    height: 150px;
  }

  .mobiel-card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .car-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
  }

  .car-details svg {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .car-details p {
    margin: 0;
    font-size: 14px;
    padding-left: 5px;
  }

  .dashboard-col {
    margin-bottom: 50px;
  }

  .dashboard-space {
    margin: 0 !important;
    padding: 0 !important;
  }

  .dashboard-top {
    padding-bottom: 50px !important;
  }

  .user-auctions-background {
    height: 500px;
  }

  .create-auction-background {
    padding-bottom: 1rem;
  }

  .appointments-title {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .mobiel-auction {
    padding: 0 !important;
  }

  .auction-detail-page {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .auction-detail-page-p {
    padding-left: 10px;
    padding-right: 10px;
  }

  .auction-detail-page-p2 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
  }

  .navbar .flag-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    display: block;
    margin-right: 10px;
  }

  .responsive-title {
    font-size: 1em;
  }

  .appointments-title {
    font-size: 1em;
  }

  .create-auction-title {
    font-size: 1em;
  }

  .contact-titel h1 {
    font-size: 31px;
  }

  .auctionText h1 {
    font-size: 31px;
  }

  .content-padding-bottom-lg {
    padding-bottom: 8rem;
    padding-left: 48px;
    padding-right: 48px;
  }

  .socials-modal {
    margin-top: 5rem;
  }

  .contact-section {
    height: 1350px;
  }

  .create-auction-hero {
    height: 15rem;
  }

  .content-padding-top-lg {
    padding: 0;
  }

  .user-auctions-background {
    display: none;
  }

  .nav-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .appointments-title {
    font-size: 1.5rem !important;
  }

  .titel-afspraken {
    padding-left: 0;
    padding-top: 16px;
  }

  .agenda-title {
    font-size: 1.5rem !important;
  }

  .dropdown-menu {
    position: absolute;
    width: 75%;
    max-width: 90vw;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #ccc;
  }

  .time-slots {
    width: 85%;
    display: flex;
  }

  .hoogste-bod {
    font-size: 150%;
  }

  .our-mission p {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .indexText p,
  h3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .winning-background {
    display: none;
  }

  .winning-titel {
    font-size: 1.5rem !important;
  }

  .following-background {
    display: none;
  }

  .following-title {
    font-size: 1.5rem !important;
  }

  .auctions-background {
    display: none;
  }

  .lopende-title {
    font-size: 1.5rem !important;
  }

  .won-background {
    display: none;
  }

  .winning-title {
    font-size: 1.5rem !important;
  }

  .responsive-title {
    font-size: 1.5rem !important;
  }

  .stock-bids-titel {
    font-size: 1.5rem !important;
  }

  .stock-success-button {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .carcard-img {
    height: 200px;
  }

  .stockcard_img {
    height: 200px;
  }

  .responsive-title {
    font-size: 3em;
  }

  .appointments-title {
    font-size: 3em;
  }

  .create-auction-title {
    font-size: 3em;
  }
}

.eenlijn {
  list-style: none;
  padding: 0;
  margin: 0;
}

.eenlijn li {
  display: inline-block;
  margin-right: 15px;
}

.responsive-title {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.animated-button {
  transition: all 0.3s ease;
}

.animated-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(33, 150, 243, 0.4);
}

.hero {
  min-height: 100vh;
  background-size: cover;
  padding-top: 18rem;
  color: white;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.6);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #2196f3;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hover-card {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.hover-card:hover {
  box-shadow: 0px 8px 20px rgba(0, 128, 255, 0.4);
  transform: translateY(-5px);
}

.hover-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: transform 0.5s ease;
}

.hover-card:hover:before {
  transform: translateX(50%);
}

.section-divider {
  border-top: 2px solid white;
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-left {
  opacity: 0;
  animation: fadeInLeft 1s forwards;
}

.fade-in-right {
  opacity: 0;
  animation: fadeInRight 1s forwards;
}

.onboarding-button {
  color: #333;
  background-color: #007bff;
  border-color: #007bff;
}

.onboarding-text {
  color: white;
}

.footer a:hover {
  color: #fff;
  transform: scale(1.1);
  transition: transform 0.2s, color 0.2s;
}

.notification-item {
  transition: background-color 0.3s ease;
}

.notification-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.notification-toggle:hover {
  background-color: #f8f9fa;
  border-color: #e2e6ea;
  cursor: pointer;
}

.notification-dropdown-menu {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  position: absolute;
  right: 0;
  top: 100px !important;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 12px;
  border-radius: 5px;
  z-index: 1050;
  overflow: hidden;
  left: 100px;
}

.notification-container {
  overflow: visible;
}

.popup-container {
  position: fixed;
  top: 135px;
  left: 10px;
  z-index: 1050;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  max-width: 300px;
  animation: fadeIn 0.3s ease-in-out;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 12px;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  margin-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.notification-message {
  margin: 0;
}

.notification-timestamp {
  color: #888888;
  font-size: 0.9em;
}

.close-button {
  margin-top: 12px;
  align-self: flex-end;
}

/* extra css features frontend */

/* START MOUSE EFFECT */

.hero {
  perspective: 1000px;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: white;
  padding: 100px 0;
}

.hero-content {
  transform-style: preserve-3d;
  transition: transform 0.1s ease-out;
}

.hero:hover .hero-content {
  transform: rotateX(calc(-10deg + 20deg * var(--mouse-y)))
    rotateY(calc(-10deg + 20deg * var(--mouse-x)));
}

/* END MOUSE EFFECT : volgt hero adhv mouse bewegingen */

/* Button effect start */

.join-button {
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
}

.join-button:hover {
  background: linear-gradient(90deg, #2575fc 0%, #6a11cb 100%);
  transform: scale(1.1);
}

.glow-button {
  position: relative;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.3s ease;
}

.glow-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.5) 0%,
    transparent 80%
  );
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease-out;
  pointer-events: none;
}

.glow-button:hover::before {
  transform: translate(var(--x), var(--y)) scale(1.5);
}

/* Button effect end */

.create-auction-hero {
  position: relative;
  color: white;
  overflow: hidden;
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/USELL/dashboard.jpeg") center no-repeat;
  background-size: cover;
  border-bottom: 4px solid #2196f3;
}

.user-auctions-hero {
  position: relative;
  color: white;
  overflow: hidden;
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/sell-overlay.jpg") center no-repeat;
  background-size: cover;
  border-bottom: 4px solid #2196f3;
}

.detail-auctions-hero {
  position: relative;
  color: white;
  overflow: hidden;
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/cars/audi.jpeg") center no-repeat;
  background-size: cover;
  border-bottom: 4px solid #2196f3;
}

.space-under {
  padding-bottom: 25px;
}

.button-centraal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-bottom: 30px;
}

.visit-counter {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  margin-top: 10px;
  display: flex;
  align-items: right;
}
.visit-counter::before {
  content: "?? ";
  margin-right: 5px;
}

.disclaimer-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 20px auto;
  max-width: 90%;
  background: linear-gradient(135deg, #ffcc00, #ff6600);
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  position: relative;
}

.disclaimer-card {
  background: transparent;
  border: none;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.exclamation-icon {
  display: inline-block;
  font-size: 1.8rem;
  margin: 0 10px;
  animation: pulse 1.5s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.exclamation-icon.after {
  margin-left: 10px;
}
